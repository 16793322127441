

#table_sheet > table  {
    border-spacing: 0px;
}

#table_sheet > table > tbody > tr > td {
    border-top: 1px solid #dadada;
    border-left: 1px solid #dadada;
    min-width: 35px;
    height: 35px;
}
#table_sheet > table > tbody > tr > td:last-child {
    border-right: 1px solid #dadada;
}
#table_sheet > table > tbody > tr:last-child > td {
    border-bottom: 1px solid #dadada;
}