/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap'); */
@font-face {
	font-family: "Poppins";
	src: url("/public/font/pxiByp8kv8JHgFVrLCz7Z1xlFd2JQEk.woff2") format("woff2");
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Poppins";
	src: url("/public/font/pxiByp8kv8JHgFVrLGT9Z1xlFd2JQEk.woff2") format("woff2");
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

body {
	margin: 0;
	/* font-family:"Poppins", sans-serif; */
	/* font-family: -apple-system, BlinkMacSystemFont, "Inter", "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
